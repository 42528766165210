import { default as index17EcTN28VEMeta } from "/builds/zone/dev/web/zone-service-top/pages/1second/index.vue?macro=true";
import { default as apppMqQkheQGPMeta } from "/builds/zone/dev/web/zone-service-top/pages/about/app.vue?macro=true";
import { default as flow4hHR9wq4MwMeta } from "/builds/zone/dev/web/zone-service-top/pages/about/flow.vue?macro=true";
import { default as index0TYNaJCDUaMeta } from "/builds/zone/dev/web/zone-service-top/pages/about/index.vue?macro=true";
import { default as summaryeYB9WQVC3XMeta } from "/builds/zone/dev/web/zone-service-top/pages/about/summary.vue?macro=true";
import { default as indexzw4JaCWQ1yMeta } from "/builds/zone/dev/web/zone-service-top/pages/app_detail/index.vue?macro=true";
import { default as banklistCSmrTFTLuFMeta } from "/builds/zone/dev/web/zone-service-top/pages/apply/banklist.vue?macro=true";
import { default as certificatel3sXVGMORNMeta } from "/builds/zone/dev/web/zone-service-top/pages/apply/certificate.vue?macro=true";
import { default as docsvI7ASrtpF3Meta } from "/builds/zone/dev/web/zone-service-top/pages/apply/docs.vue?macro=true";
import { default as identificationuODUp7z2bHMeta } from "/builds/zone/dev/web/zone-service-top/pages/apply/identification.vue?macro=true";
import { default as indexGGJghmsEZmMeta } from "/builds/zone/dev/web/zone-service-top/pages/apply/index.vue?macro=true";
import { default as _1million_in_savingsTMNr3PbPC4Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/1million_in_savings.vue?macro=true";
import { default as about_interestin5J2Baao5Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/about_interest.vue?macro=true";
import { default as about_revolving_repaymentb0fpcTsKi7Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/about_revolving_repayment.vue?macro=true";
import { default as advancepaymentQNN68I4zl0Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/advancepayment.vue?macro=true";
import { default as bankrelated_cardloanIrtgXVQlWTMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/bankrelated_cardloan.vue?macro=true";
import { default as better_repayment9yA6giHXmvMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/better_repayment.vue?macro=true";
import { default as bonus_savings9gCodatGi8Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/bonus_savings.vue?macro=true";
import { default as borrow_moneyr3hBjAljteMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/borrow_money.vue?macro=true";
import { default as borrowing_other_companiesi9kSNkAU2YMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/borrowing_other_companies.vue?macro=true";
import { default as borrowingZ5Vkj6Acv5Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/borrowing.vue?macro=true";
import { default as borrowingbalancehhnsYf14fUMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/borrowingbalance.vue?macro=true";
import { default as both_working2kDpQfCO8iMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/both_working.vue?macro=true";
import { default as bridal_loanbVezBYs6tcMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/bridal_loan.vue?macro=true";
import { default as business_loankInBMskWX4Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/business_loan.vue?macro=true";
import { default as buy_rentXXRtgrOchGMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/buy_rent.vue?macro=true";
import { default as caching_cardloand0nxCcaGupMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/caching_cardloan.vue?macro=true";
import { default as cancel_cardloan6gJ8Dk7rDcMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/cancel_cardloan.vue?macro=true";
import { default as car_loanjVLICzD1REMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/car_loan.vue?macro=true";
import { default as cardloan_balance2LbfJXE04iMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/cardloan_balance.vue?macro=true";
import { default as cardloan_creditcardIo0EMcK3jMMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/cardloan_creditcard.vue?macro=true";
import { default as cardloan_demeritN5nslNqM2hMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/cardloan_demerit.vue?macro=true";
import { default as cardloan_freeloanToaa5rZOvQMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/cardloan_freeloan.vue?macro=true";
import { default as cardloan_repaymentCEj1BSG8sqMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/cardloan_repayment.vue?macro=true";
import { default as cardloanwiseXKQ0O0HrNfMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/cardloanwise.vue?macro=true";
import { default as coefficientHCVxm5HuSVMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/coefficient.vue?macro=true";
import { default as column001FjU88ogIHyMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column001.vue?macro=true";
import { default as column002AejDC1ul4JMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column002.vue?macro=true";
import { default as column003vwtOjwhS9bMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column003.vue?macro=true";
import { default as column004zAnNKu8n3WMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column004.vue?macro=true";
import { default as column005cxuqxiaJukMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column005.vue?macro=true";
import { default as column006ax2wRyQcloMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column006.vue?macro=true";
import { default as column007x6BTlbHveYMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column007.vue?macro=true";
import { default as column008i0oOt4pvrYMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column008.vue?macro=true";
import { default as column009GJnEyUOKmjMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column009.vue?macro=true";
import { default as column010gdl6NLEaKZMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column010.vue?macro=true";
import { default as column011QWbliUxJ7IMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column011.vue?macro=true";
import { default as column012A8oKThXsRIMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column012.vue?macro=true";
import { default as column013p3NnfY0rISMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column013.vue?macro=true";
import { default as column014GtheoSGqIfMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column014.vue?macro=true";
import { default as column015nwsvsWFusvMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/column015.vue?macro=true";
import { default as consumer_finance_interest_ratessFZi50CNKsMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/consumer_finance_interest_rates.vue?macro=true";
import { default as consumerloan_annualincomeMitf7oP2ntMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/consumerloan_annualincome.vue?macro=true";
import { default as consumerloannvlFqMRKMrMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/consumerloan.vue?macro=true";
import { default as contractpoints1ALqVaadKdiMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/contractpoints1.vue?macro=true";
import { default as contractpoints2VwXgOSFQbfMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/contractpoints2.vue?macro=true";
import { default as contractpoints3TqnnjqMdTQMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/contractpoints3.vue?macro=true";
import { default as creditcard_cardloanVdg5MZz1cyMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/creditcard_cardloan.vue?macro=true";
import { default as debtyRNMnKwYQQMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/debt.vue?macro=true";
import { default as debtconsolidationtw7JWACEDmMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/debtconsolidation.vue?macro=true";
import { default as dental_loan7QBELa6KIAMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/dental_loan.vue?macro=true";
import { default as education_loanRstvhL5uK5Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/education_loan.vue?macro=true";
import { default as educationcostsVBU0cp6w1uMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/educationcosts.vue?macro=true";
import { default as enrollmentcheck5qipy9Qo53Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/enrollmentcheck.vue?macro=true";
import { default as first_timerJvHjCoOc0Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/first_time.vue?macro=true";
import { default as housing_loan_cardloanZ5VakwR2NuMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/housing_loan_cardloan.vue?macro=true";
import { default as howtoKVLaSX5mZvMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/howto.vue?macro=true";
import { default as incomeproofEJaIMWqrnUMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/incomeproof.vue?macro=true";
import { default as increasenskIkKOoyhMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/increase.vue?macro=true";
import { default as indexKT0W990WLnMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/index.vue?macro=true";
import { default as interestrate_cardloanh9GE4nbOFxMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/interestrate_cardloan.vue?macro=true";
import { default as interestrateECyG9WK2W6Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/interestrate.vue?macro=true";
import { default as investment_management0LPtyjEn4MMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/investment_management.vue?macro=true";
import { default as investment_trust_nisahGydzQbwz1Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/investment_trust_nisa.vue?macro=true";
import { default as investment_trust4KO2dZk1TBMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/investment_trust.vue?macro=true";
import { default as judgingB0MkUFQ3bVMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/judging.vue?macro=true";
import { default as loanYH4UKASj7OMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/loan.vue?macro=true";
import { default as loanvalueZlAM7bPFapMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/loanvalue.vue?macro=true";
import { default as lump_sum_repayment1YsOSKGdGrMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/lump_sum_repayment.vue?macro=true";
import { default as medical_loanLZqorpjeS6Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/medical_loan.vue?macro=true";
import { default as moneybalance119BEZAi6h7Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/moneybalance1.vue?macro=true";
import { default as moneybalance2mN0hhnzrn1Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/moneybalance2.vue?macro=true";
import { default as multiplecardloanIu07NAPlPmMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/multiplecardloan.vue?macro=true";
import { default as needmoneyWEcm44DADsMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/needmoney.vue?macro=true";
import { default as nisa_demeritojD9mYEANXMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/nisa_demerit.vue?macro=true";
import { default as nisa_idecoKYHyYtGQS3Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/nisa_ideco.vue?macro=true";
import { default as nisa_startingTN16vWAhV2Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/nisa_starting.vue?macro=true";
import { default as nonbankn0M8q4E8a8Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/nonbank.vue?macro=true";
import { default as onemillion_borrowingTJ2qlrneL2Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/onemillion_borrowing.vue?macro=true";
import { default as online_cachingvQPLKNbRe1Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/online_caching.vue?macro=true";
import { default as personal_loangShkMrFfXZMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/personal_loan.vue?macro=true";
import { default as private_bankingT2ZtJ8b4zQMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/private_banking.vue?macro=true";
import { default as real_estate_collateralFbgsY1CTKZMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/real_estate_collateral.vue?macro=true";
import { default as refinancingqdkDqfStwOMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/refinancing.vue?macro=true";
import { default as reform_loanB75gEA9pUoMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/reform_loan.vue?macro=true";
import { default as repaymentperiodpTuOtwilwAMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/repaymentperiod.vue?macro=true";
import { default as revolving_repayment_card_loanqZynE5wGUDMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/revolving_repayment_card_loan.vue?macro=true";
import { default as securities_backed_loanyqlRj3vmrVMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/securities_backed_loan.vue?macro=true";
import { default as shareholder_benefitsn6U8AVTnwYMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/shareholder_benefits.vue?macro=true";
import { default as simulation9H7KX3GaKfMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/simulation.vue?macro=true";
import { default as totalamountcontrol3ynwxKJpR7Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/totalamountcontrol.vue?macro=true";
import { default as unsecuredloant1ARsoaSJ1Meta } from "/builds/zone/dev/web/zone-service-top/pages/article/unsecuredloan.vue?macro=true";
import { default as urgent_expenses0sF1IGw1DXMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/urgent_expenses.vue?macro=true";
import { default as web_completion6GCQaDCFxEMeta } from "/builds/zone/dev/web/zone-service-top/pages/article/web_completion.vue?macro=true";
import { default as completeHIYvNlfzIFMeta } from "/builds/zone/dev/web/zone-service-top/pages/bank_transfer/complete.vue?macro=true";
import { default as failureAlJTIbXAGJMeta } from "/builds/zone/dev/web/zone-service-top/pages/bank_transfer/failure.vue?macro=true";
import { default as interruptionvuHn0HhP2aMeta } from "/builds/zone/dev/web/zone-service-top/pages/bank_transfer/interruption.vue?macro=true";
import { default as startsPaqN89CA9Meta } from "/builds/zone/dev/web/zone-service-top/pages/bank_transfer/start.vue?macro=true";
import { default as banktrowijk8RXMeta } from "/builds/zone/dev/web/zone-service-top/pages/borrow/bank.vue?macro=true";
import { default as benefitNSVYNrJex5Meta } from "/builds/zone/dev/web/zone-service-top/pages/borrow/benefit.vue?macro=true";
import { default as d_payment7UG3TcHHWKMeta } from "/builds/zone/dev/web/zone-service-top/pages/borrow/d_payment.vue?macro=true";
import { default as indexL7tdHNEec0Meta } from "/builds/zone/dev/web/zone-service-top/pages/borrow/index.vue?macro=true";
import { default as limitsBk40Vv3zsMeta } from "/builds/zone/dev/web/zone-service-top/pages/borrow/limit.vue?macro=true";
import { default as lineupAQoCcRa4OTMeta } from "/builds/zone/dev/web/zone-service-top/pages/borrow/lineup.vue?macro=true";
import { default as rategCWSX05opgMeta } from "/builds/zone/dev/web/zone-service-top/pages/borrow/rate.vue?macro=true";
import { default as indexf6Pm7Oe7QgMeta } from "/builds/zone/dev/web/zone-service-top/pages/borrow/simulation/index.vue?macro=true";
import { default as planGnCp2Y2MB1Meta } from "/builds/zone/dev/web/zone-service-top/pages/borrow/simulation/plan.vue?macro=true";
import { default as resultJP619i7WyHMeta } from "/builds/zone/dev/web/zone-service-top/pages/borrow/simulation/result.vue?macro=true";
import { default as _91id_93qw74Pe6mKVMeta } from "/builds/zone/dev/web/zone-service-top/pages/campaign/[id].vue?macro=true";
import { default as indexTcBNUuq6c1Meta } from "/builds/zone/dev/web/zone-service-top/pages/campaign/index.vue?macro=true";
import { default as _91id_93kGok1LhqRGMeta } from "/builds/zone/dev/web/zone-service-top/pages/faq/[category]/[id].vue?macro=true";
import { default as indexQZJshyAwyVMeta } from "/builds/zone/dev/web/zone-service-top/pages/faq/[category]/index.vue?macro=true";
import { default as indexu5dCvgCKyJMeta } from "/builds/zone/dev/web/zone-service-top/pages/faq/index.vue?macro=true";
import { default as indexocBudGvlk6Meta } from "/builds/zone/dev/web/zone-service-top/pages/index.vue?macro=true";
import { default as _91id_93afuH7lTVdUMeta } from "/builds/zone/dev/web/zone-service-top/pages/info/[id].vue?macro=true";
import { default as indexA9fYEzEnixMeta } from "/builds/zone/dev/web/zone-service-top/pages/info/index.vue?macro=true";
import { default as chat1kyklZzFYIMeta } from "/builds/zone/dev/web/zone-service-top/pages/inquiry/chat.vue?macro=true";
import { default as index1l6Leg8yUoMeta } from "/builds/zone/dev/web/zone-service-top/pages/inquiry/index.vue?macro=true";
import { default as d_payment_chgU041FaqR7VMeta } from "/builds/zone/dev/web/zone-service-top/pages/lp/d_payment_chg.vue?macro=true";
import { default as d_payment4BSWBQohP3Meta } from "/builds/zone/dev/web/zone-service-top/pages/lp/d_payment.vue?macro=true";
import { default as indexj4Wvqqw5S4Meta } from "/builds/zone/dev/web/zone-service-top/pages/redirect/index.vue?macro=true";
import { default as bankUTseD5emUxMeta } from "/builds/zone/dev/web/zone-service-top/pages/repay/bank.vue?macro=true";
import { default as bulletMUTXtdq43jMeta } from "/builds/zone/dev/web/zone-service-top/pages/repay/bullet.vue?macro=true";
import { default as d_paymentJAQUQgo7NwMeta } from "/builds/zone/dev/web/zone-service-top/pages/repay/d_payment.vue?macro=true";
import { default as indexkbtDGICTycMeta } from "/builds/zone/dev/web/zone-service-top/pages/repay/index.vue?macro=true";
import { default as repaymentAJmAHr3daKMeta } from "/builds/zone/dev/web/zone-service-top/pages/repay/repayment.vue?macro=true";
import { default as term6SAZFUNYGBMeta } from "/builds/zone/dev/web/zone-service-top/pages/repay/term.vue?macro=true";
import { default as indexoE6SMTpPM9Meta } from "/builds/zone/dev/web/zone-service-top/pages/terms/index.vue?macro=true";
import { default as _91id_93ZANivwVtvNMeta } from "/builds/zone/dev/web/zone-service-top/pages/termslist/[id].vue?macro=true";
import { default as indexhaq5NeeyQJMeta } from "/builds/zone/dev/web/zone-service-top/pages/termslist/index.vue?macro=true";
export default [
  {
    name: index17EcTN28VEMeta?.name ?? "1second",
    path: index17EcTN28VEMeta?.path ?? "/1second",
    meta: index17EcTN28VEMeta || {},
    alias: index17EcTN28VEMeta?.alias || [],
    redirect: index17EcTN28VEMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/1second/index.vue").then(m => m.default || m)
  },
  {
    name: apppMqQkheQGPMeta?.name ?? "about-app",
    path: apppMqQkheQGPMeta?.path ?? "/about/app",
    meta: apppMqQkheQGPMeta || {},
    alias: apppMqQkheQGPMeta?.alias || [],
    redirect: apppMqQkheQGPMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/about/app.vue").then(m => m.default || m)
  },
  {
    name: flow4hHR9wq4MwMeta?.name ?? "about-flow",
    path: flow4hHR9wq4MwMeta?.path ?? "/about/flow",
    meta: flow4hHR9wq4MwMeta || {},
    alias: flow4hHR9wq4MwMeta?.alias || [],
    redirect: flow4hHR9wq4MwMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/about/flow.vue").then(m => m.default || m)
  },
  {
    name: index0TYNaJCDUaMeta?.name ?? "about",
    path: index0TYNaJCDUaMeta?.path ?? "/about",
    meta: index0TYNaJCDUaMeta || {},
    alias: index0TYNaJCDUaMeta?.alias || [],
    redirect: index0TYNaJCDUaMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: summaryeYB9WQVC3XMeta?.name ?? "about-summary",
    path: summaryeYB9WQVC3XMeta?.path ?? "/about/summary",
    meta: summaryeYB9WQVC3XMeta || {},
    alias: summaryeYB9WQVC3XMeta?.alias || [],
    redirect: summaryeYB9WQVC3XMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/about/summary.vue").then(m => m.default || m)
  },
  {
    name: indexzw4JaCWQ1yMeta?.name ?? "app_detail",
    path: indexzw4JaCWQ1yMeta?.path ?? "/app_detail",
    meta: indexzw4JaCWQ1yMeta || {},
    alias: indexzw4JaCWQ1yMeta?.alias || [],
    redirect: indexzw4JaCWQ1yMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/app_detail/index.vue").then(m => m.default || m)
  },
  {
    name: banklistCSmrTFTLuFMeta?.name ?? "apply-banklist",
    path: banklistCSmrTFTLuFMeta?.path ?? "/apply/banklist",
    meta: banklistCSmrTFTLuFMeta || {},
    alias: banklistCSmrTFTLuFMeta?.alias || [],
    redirect: banklistCSmrTFTLuFMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/apply/banklist.vue").then(m => m.default || m)
  },
  {
    name: certificatel3sXVGMORNMeta?.name ?? "apply-certificate",
    path: certificatel3sXVGMORNMeta?.path ?? "/apply/certificate",
    meta: certificatel3sXVGMORNMeta || {},
    alias: certificatel3sXVGMORNMeta?.alias || [],
    redirect: certificatel3sXVGMORNMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/apply/certificate.vue").then(m => m.default || m)
  },
  {
    name: docsvI7ASrtpF3Meta?.name ?? "apply-docs",
    path: docsvI7ASrtpF3Meta?.path ?? "/apply/docs",
    meta: docsvI7ASrtpF3Meta || {},
    alias: docsvI7ASrtpF3Meta?.alias || [],
    redirect: docsvI7ASrtpF3Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/apply/docs.vue").then(m => m.default || m)
  },
  {
    name: identificationuODUp7z2bHMeta?.name ?? "apply-identification",
    path: identificationuODUp7z2bHMeta?.path ?? "/apply/identification",
    meta: identificationuODUp7z2bHMeta || {},
    alias: identificationuODUp7z2bHMeta?.alias || [],
    redirect: identificationuODUp7z2bHMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/apply/identification.vue").then(m => m.default || m)
  },
  {
    name: indexGGJghmsEZmMeta?.name ?? "apply",
    path: indexGGJghmsEZmMeta?.path ?? "/apply",
    meta: indexGGJghmsEZmMeta || {},
    alias: indexGGJghmsEZmMeta?.alias || [],
    redirect: indexGGJghmsEZmMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/apply/index.vue").then(m => m.default || m)
  },
  {
    name: _1million_in_savingsTMNr3PbPC4Meta?.name ?? "article-1million_in_savings",
    path: _1million_in_savingsTMNr3PbPC4Meta?.path ?? "/article/1million_in_savings",
    meta: _1million_in_savingsTMNr3PbPC4Meta || {},
    alias: _1million_in_savingsTMNr3PbPC4Meta?.alias || [],
    redirect: _1million_in_savingsTMNr3PbPC4Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/1million_in_savings.vue").then(m => m.default || m)
  },
  {
    name: about_interestin5J2Baao5Meta?.name ?? "article-about_interest",
    path: about_interestin5J2Baao5Meta?.path ?? "/article/about_interest",
    meta: about_interestin5J2Baao5Meta || {},
    alias: about_interestin5J2Baao5Meta?.alias || [],
    redirect: about_interestin5J2Baao5Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/about_interest.vue").then(m => m.default || m)
  },
  {
    name: about_revolving_repaymentb0fpcTsKi7Meta?.name ?? "article-about_revolving_repayment",
    path: about_revolving_repaymentb0fpcTsKi7Meta?.path ?? "/article/about_revolving_repayment",
    meta: about_revolving_repaymentb0fpcTsKi7Meta || {},
    alias: about_revolving_repaymentb0fpcTsKi7Meta?.alias || [],
    redirect: about_revolving_repaymentb0fpcTsKi7Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/about_revolving_repayment.vue").then(m => m.default || m)
  },
  {
    name: advancepaymentQNN68I4zl0Meta?.name ?? "article-advancepayment",
    path: advancepaymentQNN68I4zl0Meta?.path ?? "/article/advancepayment",
    meta: advancepaymentQNN68I4zl0Meta || {},
    alias: advancepaymentQNN68I4zl0Meta?.alias || [],
    redirect: advancepaymentQNN68I4zl0Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/advancepayment.vue").then(m => m.default || m)
  },
  {
    name: bankrelated_cardloanIrtgXVQlWTMeta?.name ?? "article-bankrelated_cardloan",
    path: bankrelated_cardloanIrtgXVQlWTMeta?.path ?? "/article/bankrelated_cardloan",
    meta: bankrelated_cardloanIrtgXVQlWTMeta || {},
    alias: bankrelated_cardloanIrtgXVQlWTMeta?.alias || [],
    redirect: bankrelated_cardloanIrtgXVQlWTMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/bankrelated_cardloan.vue").then(m => m.default || m)
  },
  {
    name: better_repayment9yA6giHXmvMeta?.name ?? "article-better_repayment",
    path: better_repayment9yA6giHXmvMeta?.path ?? "/article/better_repayment",
    meta: better_repayment9yA6giHXmvMeta || {},
    alias: better_repayment9yA6giHXmvMeta?.alias || [],
    redirect: better_repayment9yA6giHXmvMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/better_repayment.vue").then(m => m.default || m)
  },
  {
    name: bonus_savings9gCodatGi8Meta?.name ?? "article-bonus_savings",
    path: bonus_savings9gCodatGi8Meta?.path ?? "/article/bonus_savings",
    meta: bonus_savings9gCodatGi8Meta || {},
    alias: bonus_savings9gCodatGi8Meta?.alias || [],
    redirect: bonus_savings9gCodatGi8Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/bonus_savings.vue").then(m => m.default || m)
  },
  {
    name: borrow_moneyr3hBjAljteMeta?.name ?? "article-borrow_money",
    path: borrow_moneyr3hBjAljteMeta?.path ?? "/article/borrow_money",
    meta: borrow_moneyr3hBjAljteMeta || {},
    alias: borrow_moneyr3hBjAljteMeta?.alias || [],
    redirect: borrow_moneyr3hBjAljteMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/borrow_money.vue").then(m => m.default || m)
  },
  {
    name: borrowing_other_companiesi9kSNkAU2YMeta?.name ?? "article-borrowing_other_companies",
    path: borrowing_other_companiesi9kSNkAU2YMeta?.path ?? "/article/borrowing_other_companies",
    meta: borrowing_other_companiesi9kSNkAU2YMeta || {},
    alias: borrowing_other_companiesi9kSNkAU2YMeta?.alias || [],
    redirect: borrowing_other_companiesi9kSNkAU2YMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/borrowing_other_companies.vue").then(m => m.default || m)
  },
  {
    name: borrowingZ5Vkj6Acv5Meta?.name ?? "article-borrowing",
    path: borrowingZ5Vkj6Acv5Meta?.path ?? "/article/borrowing",
    meta: borrowingZ5Vkj6Acv5Meta || {},
    alias: borrowingZ5Vkj6Acv5Meta?.alias || [],
    redirect: borrowingZ5Vkj6Acv5Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/borrowing.vue").then(m => m.default || m)
  },
  {
    name: borrowingbalancehhnsYf14fUMeta?.name ?? "article-borrowingbalance",
    path: borrowingbalancehhnsYf14fUMeta?.path ?? "/article/borrowingbalance",
    meta: borrowingbalancehhnsYf14fUMeta || {},
    alias: borrowingbalancehhnsYf14fUMeta?.alias || [],
    redirect: borrowingbalancehhnsYf14fUMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/borrowingbalance.vue").then(m => m.default || m)
  },
  {
    name: both_working2kDpQfCO8iMeta?.name ?? "article-both_working",
    path: both_working2kDpQfCO8iMeta?.path ?? "/article/both_working",
    meta: both_working2kDpQfCO8iMeta || {},
    alias: both_working2kDpQfCO8iMeta?.alias || [],
    redirect: both_working2kDpQfCO8iMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/both_working.vue").then(m => m.default || m)
  },
  {
    name: bridal_loanbVezBYs6tcMeta?.name ?? "article-bridal_loan",
    path: bridal_loanbVezBYs6tcMeta?.path ?? "/article/bridal_loan",
    meta: bridal_loanbVezBYs6tcMeta || {},
    alias: bridal_loanbVezBYs6tcMeta?.alias || [],
    redirect: bridal_loanbVezBYs6tcMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/bridal_loan.vue").then(m => m.default || m)
  },
  {
    name: business_loankInBMskWX4Meta?.name ?? "article-business_loan",
    path: business_loankInBMskWX4Meta?.path ?? "/article/business_loan",
    meta: business_loankInBMskWX4Meta || {},
    alias: business_loankInBMskWX4Meta?.alias || [],
    redirect: business_loankInBMskWX4Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/business_loan.vue").then(m => m.default || m)
  },
  {
    name: buy_rentXXRtgrOchGMeta?.name ?? "article-buy_rent",
    path: buy_rentXXRtgrOchGMeta?.path ?? "/article/buy_rent",
    meta: buy_rentXXRtgrOchGMeta || {},
    alias: buy_rentXXRtgrOchGMeta?.alias || [],
    redirect: buy_rentXXRtgrOchGMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/buy_rent.vue").then(m => m.default || m)
  },
  {
    name: caching_cardloand0nxCcaGupMeta?.name ?? "article-caching_cardloan",
    path: caching_cardloand0nxCcaGupMeta?.path ?? "/article/caching_cardloan",
    meta: caching_cardloand0nxCcaGupMeta || {},
    alias: caching_cardloand0nxCcaGupMeta?.alias || [],
    redirect: caching_cardloand0nxCcaGupMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/caching_cardloan.vue").then(m => m.default || m)
  },
  {
    name: cancel_cardloan6gJ8Dk7rDcMeta?.name ?? "article-cancel_cardloan",
    path: cancel_cardloan6gJ8Dk7rDcMeta?.path ?? "/article/cancel_cardloan",
    meta: cancel_cardloan6gJ8Dk7rDcMeta || {},
    alias: cancel_cardloan6gJ8Dk7rDcMeta?.alias || [],
    redirect: cancel_cardloan6gJ8Dk7rDcMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/cancel_cardloan.vue").then(m => m.default || m)
  },
  {
    name: car_loanjVLICzD1REMeta?.name ?? "article-car_loan",
    path: car_loanjVLICzD1REMeta?.path ?? "/article/car_loan",
    meta: car_loanjVLICzD1REMeta || {},
    alias: car_loanjVLICzD1REMeta?.alias || [],
    redirect: car_loanjVLICzD1REMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/car_loan.vue").then(m => m.default || m)
  },
  {
    name: cardloan_balance2LbfJXE04iMeta?.name ?? "article-cardloan_balance",
    path: cardloan_balance2LbfJXE04iMeta?.path ?? "/article/cardloan_balance",
    meta: cardloan_balance2LbfJXE04iMeta || {},
    alias: cardloan_balance2LbfJXE04iMeta?.alias || [],
    redirect: cardloan_balance2LbfJXE04iMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/cardloan_balance.vue").then(m => m.default || m)
  },
  {
    name: cardloan_creditcardIo0EMcK3jMMeta?.name ?? "article-cardloan_creditcard",
    path: cardloan_creditcardIo0EMcK3jMMeta?.path ?? "/article/cardloan_creditcard",
    meta: cardloan_creditcardIo0EMcK3jMMeta || {},
    alias: cardloan_creditcardIo0EMcK3jMMeta?.alias || [],
    redirect: cardloan_creditcardIo0EMcK3jMMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/cardloan_creditcard.vue").then(m => m.default || m)
  },
  {
    name: cardloan_demeritN5nslNqM2hMeta?.name ?? "article-cardloan_demerit",
    path: cardloan_demeritN5nslNqM2hMeta?.path ?? "/article/cardloan_demerit",
    meta: cardloan_demeritN5nslNqM2hMeta || {},
    alias: cardloan_demeritN5nslNqM2hMeta?.alias || [],
    redirect: cardloan_demeritN5nslNqM2hMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/cardloan_demerit.vue").then(m => m.default || m)
  },
  {
    name: cardloan_freeloanToaa5rZOvQMeta?.name ?? "article-cardloan_freeloan",
    path: cardloan_freeloanToaa5rZOvQMeta?.path ?? "/article/cardloan_freeloan",
    meta: cardloan_freeloanToaa5rZOvQMeta || {},
    alias: cardloan_freeloanToaa5rZOvQMeta?.alias || [],
    redirect: cardloan_freeloanToaa5rZOvQMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/cardloan_freeloan.vue").then(m => m.default || m)
  },
  {
    name: cardloan_repaymentCEj1BSG8sqMeta?.name ?? "article-cardloan_repayment",
    path: cardloan_repaymentCEj1BSG8sqMeta?.path ?? "/article/cardloan_repayment",
    meta: cardloan_repaymentCEj1BSG8sqMeta || {},
    alias: cardloan_repaymentCEj1BSG8sqMeta?.alias || [],
    redirect: cardloan_repaymentCEj1BSG8sqMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/cardloan_repayment.vue").then(m => m.default || m)
  },
  {
    name: cardloanwiseXKQ0O0HrNfMeta?.name ?? "article-cardloanwise",
    path: cardloanwiseXKQ0O0HrNfMeta?.path ?? "/article/cardloanwise",
    meta: cardloanwiseXKQ0O0HrNfMeta || {},
    alias: cardloanwiseXKQ0O0HrNfMeta?.alias || [],
    redirect: cardloanwiseXKQ0O0HrNfMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/cardloanwise.vue").then(m => m.default || m)
  },
  {
    name: coefficientHCVxm5HuSVMeta?.name ?? "article-coefficient",
    path: coefficientHCVxm5HuSVMeta?.path ?? "/article/coefficient",
    meta: coefficientHCVxm5HuSVMeta || {},
    alias: coefficientHCVxm5HuSVMeta?.alias || [],
    redirect: coefficientHCVxm5HuSVMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/coefficient.vue").then(m => m.default || m)
  },
  {
    name: column001FjU88ogIHyMeta?.name ?? "article-column001",
    path: column001FjU88ogIHyMeta?.path ?? "/article/column001",
    meta: column001FjU88ogIHyMeta || {},
    alias: column001FjU88ogIHyMeta?.alias || [],
    redirect: column001FjU88ogIHyMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column001.vue").then(m => m.default || m)
  },
  {
    name: column002AejDC1ul4JMeta?.name ?? "article-column002",
    path: column002AejDC1ul4JMeta?.path ?? "/article/column002",
    meta: column002AejDC1ul4JMeta || {},
    alias: column002AejDC1ul4JMeta?.alias || [],
    redirect: column002AejDC1ul4JMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column002.vue").then(m => m.default || m)
  },
  {
    name: column003vwtOjwhS9bMeta?.name ?? "article-column003",
    path: column003vwtOjwhS9bMeta?.path ?? "/article/column003",
    meta: column003vwtOjwhS9bMeta || {},
    alias: column003vwtOjwhS9bMeta?.alias || [],
    redirect: column003vwtOjwhS9bMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column003.vue").then(m => m.default || m)
  },
  {
    name: column004zAnNKu8n3WMeta?.name ?? "article-column004",
    path: column004zAnNKu8n3WMeta?.path ?? "/article/column004",
    meta: column004zAnNKu8n3WMeta || {},
    alias: column004zAnNKu8n3WMeta?.alias || [],
    redirect: column004zAnNKu8n3WMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column004.vue").then(m => m.default || m)
  },
  {
    name: column005cxuqxiaJukMeta?.name ?? "article-column005",
    path: column005cxuqxiaJukMeta?.path ?? "/article/column005",
    meta: column005cxuqxiaJukMeta || {},
    alias: column005cxuqxiaJukMeta?.alias || [],
    redirect: column005cxuqxiaJukMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column005.vue").then(m => m.default || m)
  },
  {
    name: column006ax2wRyQcloMeta?.name ?? "article-column006",
    path: column006ax2wRyQcloMeta?.path ?? "/article/column006",
    meta: column006ax2wRyQcloMeta || {},
    alias: column006ax2wRyQcloMeta?.alias || [],
    redirect: column006ax2wRyQcloMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column006.vue").then(m => m.default || m)
  },
  {
    name: column007x6BTlbHveYMeta?.name ?? "article-column007",
    path: column007x6BTlbHveYMeta?.path ?? "/article/column007",
    meta: column007x6BTlbHveYMeta || {},
    alias: column007x6BTlbHveYMeta?.alias || [],
    redirect: column007x6BTlbHveYMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column007.vue").then(m => m.default || m)
  },
  {
    name: column008i0oOt4pvrYMeta?.name ?? "article-column008",
    path: column008i0oOt4pvrYMeta?.path ?? "/article/column008",
    meta: column008i0oOt4pvrYMeta || {},
    alias: column008i0oOt4pvrYMeta?.alias || [],
    redirect: column008i0oOt4pvrYMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column008.vue").then(m => m.default || m)
  },
  {
    name: column009GJnEyUOKmjMeta?.name ?? "article-column009",
    path: column009GJnEyUOKmjMeta?.path ?? "/article/column009",
    meta: column009GJnEyUOKmjMeta || {},
    alias: column009GJnEyUOKmjMeta?.alias || [],
    redirect: column009GJnEyUOKmjMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column009.vue").then(m => m.default || m)
  },
  {
    name: column010gdl6NLEaKZMeta?.name ?? "article-column010",
    path: column010gdl6NLEaKZMeta?.path ?? "/article/column010",
    meta: column010gdl6NLEaKZMeta || {},
    alias: column010gdl6NLEaKZMeta?.alias || [],
    redirect: column010gdl6NLEaKZMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column010.vue").then(m => m.default || m)
  },
  {
    name: column011QWbliUxJ7IMeta?.name ?? "article-column011",
    path: column011QWbliUxJ7IMeta?.path ?? "/article/column011",
    meta: column011QWbliUxJ7IMeta || {},
    alias: column011QWbliUxJ7IMeta?.alias || [],
    redirect: column011QWbliUxJ7IMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column011.vue").then(m => m.default || m)
  },
  {
    name: column012A8oKThXsRIMeta?.name ?? "article-column012",
    path: column012A8oKThXsRIMeta?.path ?? "/article/column012",
    meta: column012A8oKThXsRIMeta || {},
    alias: column012A8oKThXsRIMeta?.alias || [],
    redirect: column012A8oKThXsRIMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column012.vue").then(m => m.default || m)
  },
  {
    name: column013p3NnfY0rISMeta?.name ?? "article-column013",
    path: column013p3NnfY0rISMeta?.path ?? "/article/column013",
    meta: column013p3NnfY0rISMeta || {},
    alias: column013p3NnfY0rISMeta?.alias || [],
    redirect: column013p3NnfY0rISMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column013.vue").then(m => m.default || m)
  },
  {
    name: column014GtheoSGqIfMeta?.name ?? "article-column014",
    path: column014GtheoSGqIfMeta?.path ?? "/article/column014",
    meta: column014GtheoSGqIfMeta || {},
    alias: column014GtheoSGqIfMeta?.alias || [],
    redirect: column014GtheoSGqIfMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column014.vue").then(m => m.default || m)
  },
  {
    name: column015nwsvsWFusvMeta?.name ?? "article-column015",
    path: column015nwsvsWFusvMeta?.path ?? "/article/column015",
    meta: column015nwsvsWFusvMeta || {},
    alias: column015nwsvsWFusvMeta?.alias || [],
    redirect: column015nwsvsWFusvMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/column015.vue").then(m => m.default || m)
  },
  {
    name: consumer_finance_interest_ratessFZi50CNKsMeta?.name ?? "article-consumer_finance_interest_rates",
    path: consumer_finance_interest_ratessFZi50CNKsMeta?.path ?? "/article/consumer_finance_interest_rates",
    meta: consumer_finance_interest_ratessFZi50CNKsMeta || {},
    alias: consumer_finance_interest_ratessFZi50CNKsMeta?.alias || [],
    redirect: consumer_finance_interest_ratessFZi50CNKsMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/consumer_finance_interest_rates.vue").then(m => m.default || m)
  },
  {
    name: consumerloan_annualincomeMitf7oP2ntMeta?.name ?? "article-consumerloan_annualincome",
    path: consumerloan_annualincomeMitf7oP2ntMeta?.path ?? "/article/consumerloan_annualincome",
    meta: consumerloan_annualincomeMitf7oP2ntMeta || {},
    alias: consumerloan_annualincomeMitf7oP2ntMeta?.alias || [],
    redirect: consumerloan_annualincomeMitf7oP2ntMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/consumerloan_annualincome.vue").then(m => m.default || m)
  },
  {
    name: consumerloannvlFqMRKMrMeta?.name ?? "article-consumerloan",
    path: consumerloannvlFqMRKMrMeta?.path ?? "/article/consumerloan",
    meta: consumerloannvlFqMRKMrMeta || {},
    alias: consumerloannvlFqMRKMrMeta?.alias || [],
    redirect: consumerloannvlFqMRKMrMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/consumerloan.vue").then(m => m.default || m)
  },
  {
    name: contractpoints1ALqVaadKdiMeta?.name ?? "article-contractpoints1",
    path: contractpoints1ALqVaadKdiMeta?.path ?? "/article/contractpoints1",
    meta: contractpoints1ALqVaadKdiMeta || {},
    alias: contractpoints1ALqVaadKdiMeta?.alias || [],
    redirect: contractpoints1ALqVaadKdiMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/contractpoints1.vue").then(m => m.default || m)
  },
  {
    name: contractpoints2VwXgOSFQbfMeta?.name ?? "article-contractpoints2",
    path: contractpoints2VwXgOSFQbfMeta?.path ?? "/article/contractpoints2",
    meta: contractpoints2VwXgOSFQbfMeta || {},
    alias: contractpoints2VwXgOSFQbfMeta?.alias || [],
    redirect: contractpoints2VwXgOSFQbfMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/contractpoints2.vue").then(m => m.default || m)
  },
  {
    name: contractpoints3TqnnjqMdTQMeta?.name ?? "article-contractpoints3",
    path: contractpoints3TqnnjqMdTQMeta?.path ?? "/article/contractpoints3",
    meta: contractpoints3TqnnjqMdTQMeta || {},
    alias: contractpoints3TqnnjqMdTQMeta?.alias || [],
    redirect: contractpoints3TqnnjqMdTQMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/contractpoints3.vue").then(m => m.default || m)
  },
  {
    name: creditcard_cardloanVdg5MZz1cyMeta?.name ?? "article-creditcard_cardloan",
    path: creditcard_cardloanVdg5MZz1cyMeta?.path ?? "/article/creditcard_cardloan",
    meta: creditcard_cardloanVdg5MZz1cyMeta || {},
    alias: creditcard_cardloanVdg5MZz1cyMeta?.alias || [],
    redirect: creditcard_cardloanVdg5MZz1cyMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/creditcard_cardloan.vue").then(m => m.default || m)
  },
  {
    name: debtyRNMnKwYQQMeta?.name ?? "article-debt",
    path: debtyRNMnKwYQQMeta?.path ?? "/article/debt",
    meta: debtyRNMnKwYQQMeta || {},
    alias: debtyRNMnKwYQQMeta?.alias || [],
    redirect: debtyRNMnKwYQQMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/debt.vue").then(m => m.default || m)
  },
  {
    name: debtconsolidationtw7JWACEDmMeta?.name ?? "article-debtconsolidation",
    path: debtconsolidationtw7JWACEDmMeta?.path ?? "/article/debtconsolidation",
    meta: debtconsolidationtw7JWACEDmMeta || {},
    alias: debtconsolidationtw7JWACEDmMeta?.alias || [],
    redirect: debtconsolidationtw7JWACEDmMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/debtconsolidation.vue").then(m => m.default || m)
  },
  {
    name: dental_loan7QBELa6KIAMeta?.name ?? "article-dental_loan",
    path: dental_loan7QBELa6KIAMeta?.path ?? "/article/dental_loan",
    meta: dental_loan7QBELa6KIAMeta || {},
    alias: dental_loan7QBELa6KIAMeta?.alias || [],
    redirect: dental_loan7QBELa6KIAMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/dental_loan.vue").then(m => m.default || m)
  },
  {
    name: education_loanRstvhL5uK5Meta?.name ?? "article-education_loan",
    path: education_loanRstvhL5uK5Meta?.path ?? "/article/education_loan",
    meta: education_loanRstvhL5uK5Meta || {},
    alias: education_loanRstvhL5uK5Meta?.alias || [],
    redirect: education_loanRstvhL5uK5Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/education_loan.vue").then(m => m.default || m)
  },
  {
    name: educationcostsVBU0cp6w1uMeta?.name ?? "article-educationcosts",
    path: educationcostsVBU0cp6w1uMeta?.path ?? "/article/educationcosts",
    meta: educationcostsVBU0cp6w1uMeta || {},
    alias: educationcostsVBU0cp6w1uMeta?.alias || [],
    redirect: educationcostsVBU0cp6w1uMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/educationcosts.vue").then(m => m.default || m)
  },
  {
    name: enrollmentcheck5qipy9Qo53Meta?.name ?? "article-enrollmentcheck",
    path: enrollmentcheck5qipy9Qo53Meta?.path ?? "/article/enrollmentcheck",
    meta: enrollmentcheck5qipy9Qo53Meta || {},
    alias: enrollmentcheck5qipy9Qo53Meta?.alias || [],
    redirect: enrollmentcheck5qipy9Qo53Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/enrollmentcheck.vue").then(m => m.default || m)
  },
  {
    name: first_timerJvHjCoOc0Meta?.name ?? "article-first_time",
    path: first_timerJvHjCoOc0Meta?.path ?? "/article/first_time",
    meta: first_timerJvHjCoOc0Meta || {},
    alias: first_timerJvHjCoOc0Meta?.alias || [],
    redirect: first_timerJvHjCoOc0Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/first_time.vue").then(m => m.default || m)
  },
  {
    name: housing_loan_cardloanZ5VakwR2NuMeta?.name ?? "article-housing_loan_cardloan",
    path: housing_loan_cardloanZ5VakwR2NuMeta?.path ?? "/article/housing_loan_cardloan",
    meta: housing_loan_cardloanZ5VakwR2NuMeta || {},
    alias: housing_loan_cardloanZ5VakwR2NuMeta?.alias || [],
    redirect: housing_loan_cardloanZ5VakwR2NuMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/housing_loan_cardloan.vue").then(m => m.default || m)
  },
  {
    name: howtoKVLaSX5mZvMeta?.name ?? "article-howto",
    path: howtoKVLaSX5mZvMeta?.path ?? "/article/howto",
    meta: howtoKVLaSX5mZvMeta || {},
    alias: howtoKVLaSX5mZvMeta?.alias || [],
    redirect: howtoKVLaSX5mZvMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/howto.vue").then(m => m.default || m)
  },
  {
    name: incomeproofEJaIMWqrnUMeta?.name ?? "article-incomeproof",
    path: incomeproofEJaIMWqrnUMeta?.path ?? "/article/incomeproof",
    meta: incomeproofEJaIMWqrnUMeta || {},
    alias: incomeproofEJaIMWqrnUMeta?.alias || [],
    redirect: incomeproofEJaIMWqrnUMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/incomeproof.vue").then(m => m.default || m)
  },
  {
    name: increasenskIkKOoyhMeta?.name ?? "article-increase",
    path: increasenskIkKOoyhMeta?.path ?? "/article/increase",
    meta: increasenskIkKOoyhMeta || {},
    alias: increasenskIkKOoyhMeta?.alias || [],
    redirect: increasenskIkKOoyhMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/increase.vue").then(m => m.default || m)
  },
  {
    name: indexKT0W990WLnMeta?.name ?? "article",
    path: indexKT0W990WLnMeta?.path ?? "/article",
    meta: indexKT0W990WLnMeta || {},
    alias: indexKT0W990WLnMeta?.alias || [],
    redirect: indexKT0W990WLnMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/index.vue").then(m => m.default || m)
  },
  {
    name: interestrate_cardloanh9GE4nbOFxMeta?.name ?? "article-interestrate_cardloan",
    path: interestrate_cardloanh9GE4nbOFxMeta?.path ?? "/article/interestrate_cardloan",
    meta: interestrate_cardloanh9GE4nbOFxMeta || {},
    alias: interestrate_cardloanh9GE4nbOFxMeta?.alias || [],
    redirect: interestrate_cardloanh9GE4nbOFxMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/interestrate_cardloan.vue").then(m => m.default || m)
  },
  {
    name: interestrateECyG9WK2W6Meta?.name ?? "article-interestrate",
    path: interestrateECyG9WK2W6Meta?.path ?? "/article/interestrate",
    meta: interestrateECyG9WK2W6Meta || {},
    alias: interestrateECyG9WK2W6Meta?.alias || [],
    redirect: interestrateECyG9WK2W6Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/interestrate.vue").then(m => m.default || m)
  },
  {
    name: investment_management0LPtyjEn4MMeta?.name ?? "article-investment_management",
    path: investment_management0LPtyjEn4MMeta?.path ?? "/article/investment_management",
    meta: investment_management0LPtyjEn4MMeta || {},
    alias: investment_management0LPtyjEn4MMeta?.alias || [],
    redirect: investment_management0LPtyjEn4MMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/investment_management.vue").then(m => m.default || m)
  },
  {
    name: investment_trust_nisahGydzQbwz1Meta?.name ?? "article-investment_trust_nisa",
    path: investment_trust_nisahGydzQbwz1Meta?.path ?? "/article/investment_trust_nisa",
    meta: investment_trust_nisahGydzQbwz1Meta || {},
    alias: investment_trust_nisahGydzQbwz1Meta?.alias || [],
    redirect: investment_trust_nisahGydzQbwz1Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/investment_trust_nisa.vue").then(m => m.default || m)
  },
  {
    name: investment_trust4KO2dZk1TBMeta?.name ?? "article-investment_trust",
    path: investment_trust4KO2dZk1TBMeta?.path ?? "/article/investment_trust",
    meta: investment_trust4KO2dZk1TBMeta || {},
    alias: investment_trust4KO2dZk1TBMeta?.alias || [],
    redirect: investment_trust4KO2dZk1TBMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/investment_trust.vue").then(m => m.default || m)
  },
  {
    name: judgingB0MkUFQ3bVMeta?.name ?? "article-judging",
    path: judgingB0MkUFQ3bVMeta?.path ?? "/article/judging",
    meta: judgingB0MkUFQ3bVMeta || {},
    alias: judgingB0MkUFQ3bVMeta?.alias || [],
    redirect: judgingB0MkUFQ3bVMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/judging.vue").then(m => m.default || m)
  },
  {
    name: loanYH4UKASj7OMeta?.name ?? "article-loan",
    path: loanYH4UKASj7OMeta?.path ?? "/article/loan",
    meta: loanYH4UKASj7OMeta || {},
    alias: loanYH4UKASj7OMeta?.alias || [],
    redirect: loanYH4UKASj7OMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/loan.vue").then(m => m.default || m)
  },
  {
    name: loanvalueZlAM7bPFapMeta?.name ?? "article-loanvalue",
    path: loanvalueZlAM7bPFapMeta?.path ?? "/article/loanvalue",
    meta: loanvalueZlAM7bPFapMeta || {},
    alias: loanvalueZlAM7bPFapMeta?.alias || [],
    redirect: loanvalueZlAM7bPFapMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/loanvalue.vue").then(m => m.default || m)
  },
  {
    name: lump_sum_repayment1YsOSKGdGrMeta?.name ?? "article-lump_sum_repayment",
    path: lump_sum_repayment1YsOSKGdGrMeta?.path ?? "/article/lump_sum_repayment",
    meta: lump_sum_repayment1YsOSKGdGrMeta || {},
    alias: lump_sum_repayment1YsOSKGdGrMeta?.alias || [],
    redirect: lump_sum_repayment1YsOSKGdGrMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/lump_sum_repayment.vue").then(m => m.default || m)
  },
  {
    name: medical_loanLZqorpjeS6Meta?.name ?? "article-medical_loan",
    path: medical_loanLZqorpjeS6Meta?.path ?? "/article/medical_loan",
    meta: medical_loanLZqorpjeS6Meta || {},
    alias: medical_loanLZqorpjeS6Meta?.alias || [],
    redirect: medical_loanLZqorpjeS6Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/medical_loan.vue").then(m => m.default || m)
  },
  {
    name: moneybalance119BEZAi6h7Meta?.name ?? "article-moneybalance1",
    path: moneybalance119BEZAi6h7Meta?.path ?? "/article/moneybalance1",
    meta: moneybalance119BEZAi6h7Meta || {},
    alias: moneybalance119BEZAi6h7Meta?.alias || [],
    redirect: moneybalance119BEZAi6h7Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/moneybalance1.vue").then(m => m.default || m)
  },
  {
    name: moneybalance2mN0hhnzrn1Meta?.name ?? "article-moneybalance2",
    path: moneybalance2mN0hhnzrn1Meta?.path ?? "/article/moneybalance2",
    meta: moneybalance2mN0hhnzrn1Meta || {},
    alias: moneybalance2mN0hhnzrn1Meta?.alias || [],
    redirect: moneybalance2mN0hhnzrn1Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/moneybalance2.vue").then(m => m.default || m)
  },
  {
    name: multiplecardloanIu07NAPlPmMeta?.name ?? "article-multiplecardloan",
    path: multiplecardloanIu07NAPlPmMeta?.path ?? "/article/multiplecardloan",
    meta: multiplecardloanIu07NAPlPmMeta || {},
    alias: multiplecardloanIu07NAPlPmMeta?.alias || [],
    redirect: multiplecardloanIu07NAPlPmMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/multiplecardloan.vue").then(m => m.default || m)
  },
  {
    name: needmoneyWEcm44DADsMeta?.name ?? "article-needmoney",
    path: needmoneyWEcm44DADsMeta?.path ?? "/article/needmoney",
    meta: needmoneyWEcm44DADsMeta || {},
    alias: needmoneyWEcm44DADsMeta?.alias || [],
    redirect: needmoneyWEcm44DADsMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/needmoney.vue").then(m => m.default || m)
  },
  {
    name: nisa_demeritojD9mYEANXMeta?.name ?? "article-nisa_demerit",
    path: nisa_demeritojD9mYEANXMeta?.path ?? "/article/nisa_demerit",
    meta: nisa_demeritojD9mYEANXMeta || {},
    alias: nisa_demeritojD9mYEANXMeta?.alias || [],
    redirect: nisa_demeritojD9mYEANXMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/nisa_demerit.vue").then(m => m.default || m)
  },
  {
    name: nisa_idecoKYHyYtGQS3Meta?.name ?? "article-nisa_ideco",
    path: nisa_idecoKYHyYtGQS3Meta?.path ?? "/article/nisa_ideco",
    meta: nisa_idecoKYHyYtGQS3Meta || {},
    alias: nisa_idecoKYHyYtGQS3Meta?.alias || [],
    redirect: nisa_idecoKYHyYtGQS3Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/nisa_ideco.vue").then(m => m.default || m)
  },
  {
    name: nisa_startingTN16vWAhV2Meta?.name ?? "article-nisa_starting",
    path: nisa_startingTN16vWAhV2Meta?.path ?? "/article/nisa_starting",
    meta: nisa_startingTN16vWAhV2Meta || {},
    alias: nisa_startingTN16vWAhV2Meta?.alias || [],
    redirect: nisa_startingTN16vWAhV2Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/nisa_starting.vue").then(m => m.default || m)
  },
  {
    name: nonbankn0M8q4E8a8Meta?.name ?? "article-nonbank",
    path: nonbankn0M8q4E8a8Meta?.path ?? "/article/nonbank",
    meta: nonbankn0M8q4E8a8Meta || {},
    alias: nonbankn0M8q4E8a8Meta?.alias || [],
    redirect: nonbankn0M8q4E8a8Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/nonbank.vue").then(m => m.default || m)
  },
  {
    name: onemillion_borrowingTJ2qlrneL2Meta?.name ?? "article-onemillion_borrowing",
    path: onemillion_borrowingTJ2qlrneL2Meta?.path ?? "/article/onemillion_borrowing",
    meta: onemillion_borrowingTJ2qlrneL2Meta || {},
    alias: onemillion_borrowingTJ2qlrneL2Meta?.alias || [],
    redirect: onemillion_borrowingTJ2qlrneL2Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/onemillion_borrowing.vue").then(m => m.default || m)
  },
  {
    name: online_cachingvQPLKNbRe1Meta?.name ?? "article-online_caching",
    path: online_cachingvQPLKNbRe1Meta?.path ?? "/article/online_caching",
    meta: online_cachingvQPLKNbRe1Meta || {},
    alias: online_cachingvQPLKNbRe1Meta?.alias || [],
    redirect: online_cachingvQPLKNbRe1Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/online_caching.vue").then(m => m.default || m)
  },
  {
    name: personal_loangShkMrFfXZMeta?.name ?? "article-personal_loan",
    path: personal_loangShkMrFfXZMeta?.path ?? "/article/personal_loan",
    meta: personal_loangShkMrFfXZMeta || {},
    alias: personal_loangShkMrFfXZMeta?.alias || [],
    redirect: personal_loangShkMrFfXZMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/personal_loan.vue").then(m => m.default || m)
  },
  {
    name: private_bankingT2ZtJ8b4zQMeta?.name ?? "article-private_banking",
    path: private_bankingT2ZtJ8b4zQMeta?.path ?? "/article/private_banking",
    meta: private_bankingT2ZtJ8b4zQMeta || {},
    alias: private_bankingT2ZtJ8b4zQMeta?.alias || [],
    redirect: private_bankingT2ZtJ8b4zQMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/private_banking.vue").then(m => m.default || m)
  },
  {
    name: real_estate_collateralFbgsY1CTKZMeta?.name ?? "article-real_estate_collateral",
    path: real_estate_collateralFbgsY1CTKZMeta?.path ?? "/article/real_estate_collateral",
    meta: real_estate_collateralFbgsY1CTKZMeta || {},
    alias: real_estate_collateralFbgsY1CTKZMeta?.alias || [],
    redirect: real_estate_collateralFbgsY1CTKZMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/real_estate_collateral.vue").then(m => m.default || m)
  },
  {
    name: refinancingqdkDqfStwOMeta?.name ?? "article-refinancing",
    path: refinancingqdkDqfStwOMeta?.path ?? "/article/refinancing",
    meta: refinancingqdkDqfStwOMeta || {},
    alias: refinancingqdkDqfStwOMeta?.alias || [],
    redirect: refinancingqdkDqfStwOMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/refinancing.vue").then(m => m.default || m)
  },
  {
    name: reform_loanB75gEA9pUoMeta?.name ?? "article-reform_loan",
    path: reform_loanB75gEA9pUoMeta?.path ?? "/article/reform_loan",
    meta: reform_loanB75gEA9pUoMeta || {},
    alias: reform_loanB75gEA9pUoMeta?.alias || [],
    redirect: reform_loanB75gEA9pUoMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/reform_loan.vue").then(m => m.default || m)
  },
  {
    name: repaymentperiodpTuOtwilwAMeta?.name ?? "article-repaymentperiod",
    path: repaymentperiodpTuOtwilwAMeta?.path ?? "/article/repaymentperiod",
    meta: repaymentperiodpTuOtwilwAMeta || {},
    alias: repaymentperiodpTuOtwilwAMeta?.alias || [],
    redirect: repaymentperiodpTuOtwilwAMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/repaymentperiod.vue").then(m => m.default || m)
  },
  {
    name: revolving_repayment_card_loanqZynE5wGUDMeta?.name ?? "article-revolving_repayment_card_loan",
    path: revolving_repayment_card_loanqZynE5wGUDMeta?.path ?? "/article/revolving_repayment_card_loan",
    meta: revolving_repayment_card_loanqZynE5wGUDMeta || {},
    alias: revolving_repayment_card_loanqZynE5wGUDMeta?.alias || [],
    redirect: revolving_repayment_card_loanqZynE5wGUDMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/revolving_repayment_card_loan.vue").then(m => m.default || m)
  },
  {
    name: securities_backed_loanyqlRj3vmrVMeta?.name ?? "article-securities_backed_loan",
    path: securities_backed_loanyqlRj3vmrVMeta?.path ?? "/article/securities_backed_loan",
    meta: securities_backed_loanyqlRj3vmrVMeta || {},
    alias: securities_backed_loanyqlRj3vmrVMeta?.alias || [],
    redirect: securities_backed_loanyqlRj3vmrVMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/securities_backed_loan.vue").then(m => m.default || m)
  },
  {
    name: shareholder_benefitsn6U8AVTnwYMeta?.name ?? "article-shareholder_benefits",
    path: shareholder_benefitsn6U8AVTnwYMeta?.path ?? "/article/shareholder_benefits",
    meta: shareholder_benefitsn6U8AVTnwYMeta || {},
    alias: shareholder_benefitsn6U8AVTnwYMeta?.alias || [],
    redirect: shareholder_benefitsn6U8AVTnwYMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/shareholder_benefits.vue").then(m => m.default || m)
  },
  {
    name: simulation9H7KX3GaKfMeta?.name ?? "article-simulation",
    path: simulation9H7KX3GaKfMeta?.path ?? "/article/simulation",
    meta: simulation9H7KX3GaKfMeta || {},
    alias: simulation9H7KX3GaKfMeta?.alias || [],
    redirect: simulation9H7KX3GaKfMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/simulation.vue").then(m => m.default || m)
  },
  {
    name: totalamountcontrol3ynwxKJpR7Meta?.name ?? "article-totalamountcontrol",
    path: totalamountcontrol3ynwxKJpR7Meta?.path ?? "/article/totalamountcontrol",
    meta: totalamountcontrol3ynwxKJpR7Meta || {},
    alias: totalamountcontrol3ynwxKJpR7Meta?.alias || [],
    redirect: totalamountcontrol3ynwxKJpR7Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/totalamountcontrol.vue").then(m => m.default || m)
  },
  {
    name: unsecuredloant1ARsoaSJ1Meta?.name ?? "article-unsecuredloan",
    path: unsecuredloant1ARsoaSJ1Meta?.path ?? "/article/unsecuredloan",
    meta: unsecuredloant1ARsoaSJ1Meta || {},
    alias: unsecuredloant1ARsoaSJ1Meta?.alias || [],
    redirect: unsecuredloant1ARsoaSJ1Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/unsecuredloan.vue").then(m => m.default || m)
  },
  {
    name: urgent_expenses0sF1IGw1DXMeta?.name ?? "article-urgent_expenses",
    path: urgent_expenses0sF1IGw1DXMeta?.path ?? "/article/urgent_expenses",
    meta: urgent_expenses0sF1IGw1DXMeta || {},
    alias: urgent_expenses0sF1IGw1DXMeta?.alias || [],
    redirect: urgent_expenses0sF1IGw1DXMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/urgent_expenses.vue").then(m => m.default || m)
  },
  {
    name: web_completion6GCQaDCFxEMeta?.name ?? "article-web_completion",
    path: web_completion6GCQaDCFxEMeta?.path ?? "/article/web_completion",
    meta: web_completion6GCQaDCFxEMeta || {},
    alias: web_completion6GCQaDCFxEMeta?.alias || [],
    redirect: web_completion6GCQaDCFxEMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/article/web_completion.vue").then(m => m.default || m)
  },
  {
    name: completeHIYvNlfzIFMeta?.name ?? "bank_transfer-complete",
    path: completeHIYvNlfzIFMeta?.path ?? "/bank_transfer/complete",
    meta: completeHIYvNlfzIFMeta || {},
    alias: completeHIYvNlfzIFMeta?.alias || [],
    redirect: completeHIYvNlfzIFMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/bank_transfer/complete.vue").then(m => m.default || m)
  },
  {
    name: failureAlJTIbXAGJMeta?.name ?? "bank_transfer-failure",
    path: failureAlJTIbXAGJMeta?.path ?? "/bank_transfer/failure",
    meta: failureAlJTIbXAGJMeta || {},
    alias: failureAlJTIbXAGJMeta?.alias || [],
    redirect: failureAlJTIbXAGJMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/bank_transfer/failure.vue").then(m => m.default || m)
  },
  {
    name: interruptionvuHn0HhP2aMeta?.name ?? "bank_transfer-interruption",
    path: interruptionvuHn0HhP2aMeta?.path ?? "/bank_transfer/interruption",
    meta: interruptionvuHn0HhP2aMeta || {},
    alias: interruptionvuHn0HhP2aMeta?.alias || [],
    redirect: interruptionvuHn0HhP2aMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/bank_transfer/interruption.vue").then(m => m.default || m)
  },
  {
    name: startsPaqN89CA9Meta?.name ?? "bank_transfer-start",
    path: startsPaqN89CA9Meta?.path ?? "/bank_transfer/start",
    meta: startsPaqN89CA9Meta || {},
    alias: startsPaqN89CA9Meta?.alias || [],
    redirect: startsPaqN89CA9Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/bank_transfer/start.vue").then(m => m.default || m)
  },
  {
    name: banktrowijk8RXMeta?.name ?? "borrow-bank",
    path: banktrowijk8RXMeta?.path ?? "/borrow/bank",
    meta: banktrowijk8RXMeta || {},
    alias: banktrowijk8RXMeta?.alias || [],
    redirect: banktrowijk8RXMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/borrow/bank.vue").then(m => m.default || m)
  },
  {
    name: benefitNSVYNrJex5Meta?.name ?? "borrow-benefit",
    path: benefitNSVYNrJex5Meta?.path ?? "/borrow/benefit",
    meta: benefitNSVYNrJex5Meta || {},
    alias: benefitNSVYNrJex5Meta?.alias || [],
    redirect: benefitNSVYNrJex5Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/borrow/benefit.vue").then(m => m.default || m)
  },
  {
    name: d_payment7UG3TcHHWKMeta?.name ?? "borrow-d_payment",
    path: d_payment7UG3TcHHWKMeta?.path ?? "/borrow/d_payment",
    meta: d_payment7UG3TcHHWKMeta || {},
    alias: d_payment7UG3TcHHWKMeta?.alias || [],
    redirect: d_payment7UG3TcHHWKMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/borrow/d_payment.vue").then(m => m.default || m)
  },
  {
    name: indexL7tdHNEec0Meta?.name ?? "borrow",
    path: indexL7tdHNEec0Meta?.path ?? "/borrow",
    meta: indexL7tdHNEec0Meta || {},
    alias: indexL7tdHNEec0Meta?.alias || [],
    redirect: indexL7tdHNEec0Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/borrow/index.vue").then(m => m.default || m)
  },
  {
    name: limitsBk40Vv3zsMeta?.name ?? "borrow-limit",
    path: limitsBk40Vv3zsMeta?.path ?? "/borrow/limit",
    meta: limitsBk40Vv3zsMeta || {},
    alias: limitsBk40Vv3zsMeta?.alias || [],
    redirect: limitsBk40Vv3zsMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/borrow/limit.vue").then(m => m.default || m)
  },
  {
    name: lineupAQoCcRa4OTMeta?.name ?? "borrow-lineup",
    path: lineupAQoCcRa4OTMeta?.path ?? "/borrow/lineup",
    meta: lineupAQoCcRa4OTMeta || {},
    alias: lineupAQoCcRa4OTMeta?.alias || [],
    redirect: lineupAQoCcRa4OTMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/borrow/lineup.vue").then(m => m.default || m)
  },
  {
    name: rategCWSX05opgMeta?.name ?? "borrow-rate",
    path: rategCWSX05opgMeta?.path ?? "/borrow/rate",
    meta: rategCWSX05opgMeta || {},
    alias: rategCWSX05opgMeta?.alias || [],
    redirect: rategCWSX05opgMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/borrow/rate.vue").then(m => m.default || m)
  },
  {
    name: indexf6Pm7Oe7QgMeta?.name ?? "borrow-simulation",
    path: indexf6Pm7Oe7QgMeta?.path ?? "/borrow/simulation",
    meta: indexf6Pm7Oe7QgMeta || {},
    alias: indexf6Pm7Oe7QgMeta?.alias || [],
    redirect: indexf6Pm7Oe7QgMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/borrow/simulation/index.vue").then(m => m.default || m)
  },
  {
    name: planGnCp2Y2MB1Meta?.name ?? "borrow-simulation-plan",
    path: planGnCp2Y2MB1Meta?.path ?? "/borrow/simulation/plan",
    meta: planGnCp2Y2MB1Meta || {},
    alias: planGnCp2Y2MB1Meta?.alias || [],
    redirect: planGnCp2Y2MB1Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/borrow/simulation/plan.vue").then(m => m.default || m)
  },
  {
    name: resultJP619i7WyHMeta?.name ?? "borrow-simulation-result",
    path: resultJP619i7WyHMeta?.path ?? "/borrow/simulation/result",
    meta: resultJP619i7WyHMeta || {},
    alias: resultJP619i7WyHMeta?.alias || [],
    redirect: resultJP619i7WyHMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/borrow/simulation/result.vue").then(m => m.default || m)
  },
  {
    name: _91id_93qw74Pe6mKVMeta?.name ?? "campaign-id",
    path: _91id_93qw74Pe6mKVMeta?.path ?? "/campaign/:id()",
    meta: _91id_93qw74Pe6mKVMeta || {},
    alias: _91id_93qw74Pe6mKVMeta?.alias || [],
    redirect: _91id_93qw74Pe6mKVMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/campaign/[id].vue").then(m => m.default || m)
  },
  {
    name: indexTcBNUuq6c1Meta?.name ?? "campaign",
    path: indexTcBNUuq6c1Meta?.path ?? "/campaign",
    meta: indexTcBNUuq6c1Meta || {},
    alias: indexTcBNUuq6c1Meta?.alias || [],
    redirect: indexTcBNUuq6c1Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/campaign/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93kGok1LhqRGMeta?.name ?? "faq-category-id",
    path: _91id_93kGok1LhqRGMeta?.path ?? "/faq/:category()/:id()",
    meta: _91id_93kGok1LhqRGMeta || {},
    alias: _91id_93kGok1LhqRGMeta?.alias || [],
    redirect: _91id_93kGok1LhqRGMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/faq/[category]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexQZJshyAwyVMeta?.name ?? "faq-category",
    path: indexQZJshyAwyVMeta?.path ?? "/faq/:category()",
    meta: indexQZJshyAwyVMeta || {},
    alias: indexQZJshyAwyVMeta?.alias || [],
    redirect: indexQZJshyAwyVMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/faq/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexu5dCvgCKyJMeta?.name ?? "faq",
    path: indexu5dCvgCKyJMeta?.path ?? "/faq",
    meta: indexu5dCvgCKyJMeta || {},
    alias: indexu5dCvgCKyJMeta?.alias || [],
    redirect: indexu5dCvgCKyJMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: indexocBudGvlk6Meta?.name ?? "index",
    path: indexocBudGvlk6Meta?.path ?? "/",
    meta: indexocBudGvlk6Meta || {},
    alias: indexocBudGvlk6Meta?.alias || [],
    redirect: indexocBudGvlk6Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93afuH7lTVdUMeta?.name ?? "info-id",
    path: _91id_93afuH7lTVdUMeta?.path ?? "/info/:id()",
    meta: _91id_93afuH7lTVdUMeta || {},
    alias: _91id_93afuH7lTVdUMeta?.alias || [],
    redirect: _91id_93afuH7lTVdUMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/info/[id].vue").then(m => m.default || m)
  },
  {
    name: indexA9fYEzEnixMeta?.name ?? "info",
    path: indexA9fYEzEnixMeta?.path ?? "/info",
    meta: indexA9fYEzEnixMeta || {},
    alias: indexA9fYEzEnixMeta?.alias || [],
    redirect: indexA9fYEzEnixMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/info/index.vue").then(m => m.default || m)
  },
  {
    name: chat1kyklZzFYIMeta?.name ?? "inquiry-chat",
    path: chat1kyklZzFYIMeta?.path ?? "/inquiry/chat",
    meta: chat1kyklZzFYIMeta || {},
    alias: chat1kyklZzFYIMeta?.alias || [],
    redirect: chat1kyklZzFYIMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/inquiry/chat.vue").then(m => m.default || m)
  },
  {
    name: index1l6Leg8yUoMeta?.name ?? "inquiry",
    path: index1l6Leg8yUoMeta?.path ?? "/inquiry",
    meta: index1l6Leg8yUoMeta || {},
    alias: index1l6Leg8yUoMeta?.alias || [],
    redirect: index1l6Leg8yUoMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/inquiry/index.vue").then(m => m.default || m)
  },
  {
    name: d_payment_chgU041FaqR7VMeta?.name ?? "lp-d_payment_chg",
    path: d_payment_chgU041FaqR7VMeta?.path ?? "/lp/d_payment_chg",
    meta: d_payment_chgU041FaqR7VMeta || {},
    alias: d_payment_chgU041FaqR7VMeta?.alias || [],
    redirect: d_payment_chgU041FaqR7VMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/lp/d_payment_chg.vue").then(m => m.default || m)
  },
  {
    name: d_payment4BSWBQohP3Meta?.name ?? "lp-d_payment",
    path: d_payment4BSWBQohP3Meta?.path ?? "/lp/d_payment",
    meta: d_payment4BSWBQohP3Meta || {},
    alias: d_payment4BSWBQohP3Meta?.alias || [],
    redirect: d_payment4BSWBQohP3Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/lp/d_payment.vue").then(m => m.default || m)
  },
  {
    name: indexj4Wvqqw5S4Meta?.name ?? "redirect",
    path: indexj4Wvqqw5S4Meta?.path ?? "/redirect",
    meta: indexj4Wvqqw5S4Meta || {},
    alias: indexj4Wvqqw5S4Meta?.alias || [],
    redirect: indexj4Wvqqw5S4Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/redirect/index.vue").then(m => m.default || m)
  },
  {
    name: bankUTseD5emUxMeta?.name ?? "repay-bank",
    path: bankUTseD5emUxMeta?.path ?? "/repay/bank",
    meta: bankUTseD5emUxMeta || {},
    alias: bankUTseD5emUxMeta?.alias || [],
    redirect: bankUTseD5emUxMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/repay/bank.vue").then(m => m.default || m)
  },
  {
    name: bulletMUTXtdq43jMeta?.name ?? "repay-bullet",
    path: bulletMUTXtdq43jMeta?.path ?? "/repay/bullet",
    meta: bulletMUTXtdq43jMeta || {},
    alias: bulletMUTXtdq43jMeta?.alias || [],
    redirect: bulletMUTXtdq43jMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/repay/bullet.vue").then(m => m.default || m)
  },
  {
    name: d_paymentJAQUQgo7NwMeta?.name ?? "repay-d_payment",
    path: d_paymentJAQUQgo7NwMeta?.path ?? "/repay/d_payment",
    meta: d_paymentJAQUQgo7NwMeta || {},
    alias: d_paymentJAQUQgo7NwMeta?.alias || [],
    redirect: d_paymentJAQUQgo7NwMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/repay/d_payment.vue").then(m => m.default || m)
  },
  {
    name: indexkbtDGICTycMeta?.name ?? "repay",
    path: indexkbtDGICTycMeta?.path ?? "/repay",
    meta: indexkbtDGICTycMeta || {},
    alias: indexkbtDGICTycMeta?.alias || [],
    redirect: indexkbtDGICTycMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/repay/index.vue").then(m => m.default || m)
  },
  {
    name: repaymentAJmAHr3daKMeta?.name ?? "repay-repayment",
    path: repaymentAJmAHr3daKMeta?.path ?? "/repay/repayment",
    meta: repaymentAJmAHr3daKMeta || {},
    alias: repaymentAJmAHr3daKMeta?.alias || [],
    redirect: repaymentAJmAHr3daKMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/repay/repayment.vue").then(m => m.default || m)
  },
  {
    name: term6SAZFUNYGBMeta?.name ?? "repay-term",
    path: term6SAZFUNYGBMeta?.path ?? "/repay/term",
    meta: term6SAZFUNYGBMeta || {},
    alias: term6SAZFUNYGBMeta?.alias || [],
    redirect: term6SAZFUNYGBMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/repay/term.vue").then(m => m.default || m)
  },
  {
    name: indexoE6SMTpPM9Meta?.name ?? "terms",
    path: indexoE6SMTpPM9Meta?.path ?? "/terms",
    meta: indexoE6SMTpPM9Meta || {},
    alias: indexoE6SMTpPM9Meta?.alias || [],
    redirect: indexoE6SMTpPM9Meta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ZANivwVtvNMeta?.name ?? "termslist-id",
    path: _91id_93ZANivwVtvNMeta?.path ?? "/termslist/:id()",
    meta: _91id_93ZANivwVtvNMeta || {},
    alias: _91id_93ZANivwVtvNMeta?.alias || [],
    redirect: _91id_93ZANivwVtvNMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/termslist/[id].vue").then(m => m.default || m)
  },
  {
    name: indexhaq5NeeyQJMeta?.name ?? "termslist",
    path: indexhaq5NeeyQJMeta?.path ?? "/termslist",
    meta: indexhaq5NeeyQJMeta || {},
    alias: indexhaq5NeeyQJMeta?.alias || [],
    redirect: indexhaq5NeeyQJMeta?.redirect,
    component: () => import("/builds/zone/dev/web/zone-service-top/pages/termslist/index.vue").then(m => m.default || m)
  }
]